import cx from 'classnames';
import React from 'react';

import {
  FlybusSchedule,
  FlybusScheduleTable,
  Title,
  Link,
  Desktop,
  Mobile,
} from '../components';
import CancellationPolicy from '../components/CancellationPolicy';
import Highlights from '../components/Highlights';
import ProductCard from '../components/ProductCard';
import { ITourDetails, ITourItem } from '../interfaces/tours';
import { ITransport } from '../interfaces/transportApi';
import * as styles from './styles/flybus.module.scss';
import { IPageContext } from '../interfaces';

interface IProps {
  contentfulTour: ITourDetails;
  transport: ITransport | null;
  productYML: IDefaultPrices[] | null;
  loadingYML: boolean;
  currency: string;
  pageContext: IPageContext;
}

interface IDefaultPrices {
  defaultPrice: number | null;
  discountPrice: number | null | undefined;
  id: number;
}

const Flybus: React.FunctionComponent<IProps> = ({
  contentfulTour,
  transport,
  productYML,
  loadingYML,
  currency,
  pageContext,
}) => {
  const { tours, airportTransfers, highlights } = contentfulTour;
  const renderProductList = (
    tours: ITourItem[] | null,
    title: string,
    link: string
  ) => {
    if (tours) {
      return (
        <div>
          <Title tag='h2' theme={['sizeMobileLarge', 'sizeDesktopLarge']}>
            {title}
          </Title>
          <div className={cx('columns is-multiline', styles.productList)}>
            {tours.slice(0, 2).map((tour) => (
              <div
                key={tour.id}
                className='column is-12-tablet is-half-desktop'
              >
                <ProductCard
                  tour={tour}
                  defaultPrices={
                    productYML &&
                    productYML.find((p) => p.id.toString() === tour.productId)
                  }
                  loadingPrices={loadingYML}
                  currency={currency}
                  langPath={pageContext.langPath}
                />
              </div>
            ))}
          </div>
          <Link langPath={pageContext.langPath} to={link}>
            <div className={styles.seeMore}>
              <span>{contentfulTour.seeMoreButton}</span>
            </div>
          </Link>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={cx('centered-content', styles.flybusWrapper)}>
      {contentfulTour.flybusSchedule && (
        <div className='columns'>
          <div className='column is-5-desktop'>
            {highlights && (
              <div className={styles.highlightsContainer}>
                <Highlights {...highlights} />
              </div>
            )}
            <div className={styles.flybusContainer}>
              <Mobile>
                <FlybusScheduleTable {...contentfulTour.flybusSchedule} />
              </Mobile>
              <FlybusSchedule {...contentfulTour.flybusSchedule} />
            </div>
            <div className={styles.transportContainer}>
              {transport &&
                transport.CancellationPolicy &&
                transport.CancellationPolicy.penaltyRules.length > 0 && (
                  <CancellationPolicy
                    penaltyRules={transport.CancellationPolicy.penaltyRules}
                  />
                )}
            </div>
          </div>

          <div className='column is-6-desktop is-offset-1'>
            <div className='columns is-multiline'>
              <div className='column is-full'>
                <Desktop>
                  <div className={styles.flybusContainer}>
                    <FlybusScheduleTable {...contentfulTour.flybusSchedule} />
                  </div>
                </Desktop>
                {renderProductList(
                  airportTransfers,
                  contentfulTour.firstProductList?.title ||
                    'Private Airport Transfers',
                  contentfulTour.firstProductList?.externalLink ||
                    'private-airport-transfer'
                )}
                {renderProductList(
                  tours,
                  contentfulTour.secondProductList?.title ||
                    'Flybus Combo Offers',
                  contentfulTour.secondProductList?.externalLink ||
                    'combo-offers'
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Flybus;
