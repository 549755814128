import React, { FunctionComponent } from 'react';
import * as styles from './index.module.scss';
import Icon from '../Icon';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import { IReducers } from '../../interfaces';
import { formatHardcodedStringWithVariables } from '../../utils';

interface IPenaltyRules {
  charge: number;
  cutoffHours: number;
  chargeType: string;
  id: number;
}

interface IExternalProps {
  locale: string;
  penaltyRules: IPenaltyRules[];
}

interface ITranslationsCancellationPolicy {
  tourDescriptionCancellationPolicyHeader: string;
  tourDescriptionCancellationPolicyText: string;
  tourDescriptionCancellationPolicyDays: string;
  tourDescriptionCancellationPolicyHours: string;
}

interface IProps extends IExternalProps {
  localeData: ITranslationsCancellationPolicy;
}

const CancellationPolicy: FunctionComponent<IProps> = (
  {
    // localeData,
    // penaltyRules,
  }
) => {
  return null;
  // let cancellationMessage = '';
  // const calculateCutOffHour = (hour: number) => {
  //   let result = null;
  //   if (hour <= 48) {
  //     result =
  //       hour + ' ' + localeData.tourDescriptionCancellationPolicyHours + ' ';
  //   } else {
  //     const Days = Math.floor(hour / 24);
  //     const Remainder = hour % 24;
  //     const Hours = Math.floor(Remainder);
  //     result =
  //       Days + ' ' + localeData.tourDescriptionCancellationPolicyDays + ' ';
  //     if (Hours > 0) {
  //       result +=
  //         Hours + ' ' + localeData.tourDescriptionCancellationPolicyHours + ' ';
  //     }
  //   }
  //   return result;
  // };
  // formatHardcodedStringWithVariables;
  // penaltyRules.forEach((item: IPenaltyRules) => {
  //   const percentage = item.charge;
  //   const cutOffHour = item.cutoffHours;
  //   cancellationMessage = formatHardcodedStringWithVariables(
  //     localeData.tourDescriptionCancellationPolicyText,
  //     [percentage.toString(), calculateCutOffHour(cutOffHour)]
  //   );
  // });
  // if (
  //   penaltyRules.length === 1 &&
  //   penaltyRules[0].cutoffHours === 0 &&
  //   penaltyRules[0].charge === 0
  // ) {
  //   return null;
  // } else {
  //   return (
  //     <div className={styles.cancellationPolicy}>
  //       <Icon name='FILEERROR' />
  //       <div className={styles.contentWrapper}>
  //         <h2 className={styles.heading}>
  //           {localeData.tourDescriptionCancellationPolicyHeader}
  //         </h2>
  //         <p className={styles.message}>{cancellationMessage}</p>
  //       </div>
  //     </div>
  //   );
  // }
};

const mapStateToProps = (reducers: IReducers) => {
  const { locale } = reducers.translationReducers;
  return { locale };
};

export default connect(mapStateToProps)(({ locale, penaltyRules }: IExternalProps) => (
  <StaticQuery
    query={graphql`
      query CancellationPolicy {
        allContentfulTranslationsPage {
          edges {
            node {
              node_locale
              tourDescriptionCancellationPolicyHeader
              tourDescriptionCancellationPolicyText
              tourDescriptionCancellationPolicyDays
              tourDescriptionCancellationPolicyHours
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <CancellationPolicy
          localeData={
            data.allContentfulTranslationsPage.edges.filter((node: { node: { node_locale: string } }) => {
              return node.node.node_locale === locale;
            })[0].node as ITranslationsCancellationPolicy
          }
          locale={locale}
          penaltyRules={penaltyRules}
        />
      );
    }}
  />
));
