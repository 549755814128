// extracted by mini-css-extract-plugin
export var moreInfo = "tourDetails-module--moreInfo--1FOAm";
export var moreInfoTransport = "tourDetails-module--moreInfoTransport--lp4Kp";
export var infoBox = "tourDetails-module--infoBox--3mvj0";
export var infoLine = "tourDetails-module--infoLine--L8Lan";
export var highlights = "tourDetails-module--highlights--XRyN2";
export var includes = "tourDetails-module--includes--2F-wn";
export var thirdParty = "tourDetails-module--thirdParty--3HS3M";
export var container = "tourDetails-module--container--2KIuS";
export var tourItem = "tourDetails-module--tourItem--3VeoA";
export var imageContainer = "tourDetails-module--imageContainer--3a0RY";
export var image = "tourDetails-module--image--2VLJo";
export var specialOfferBanner = "tourDetails-module--specialOfferBanner--3xzqX";
export var mobileImageContainer = "tourDetails-module--mobileImageContainer--1Kckn";
export var imageMobile = "tourDetails-module--imageMobile--IjEmS";
export var searchResultTitle = "tourDetails-module--searchResultTitle--1csm9";
export var card = "tourDetails-module--card--1bIbs";
export var tourItemLink = "tourDetails-module--tourItemLink--1QivB";
export var cardSliderMobile = "tourDetails-module--cardSliderMobile--2sjEm";
export var buttonBack = "tourDetails-module--buttonBack--1Zp7u";
export var buttonNext = "tourDetails-module--buttonNext--8cjfm";
export var containerCarousel = "tourDetails-module--containerCarousel--2ahRL";
export var category = "tourDetails-module--category--dmNwn";
export var title = "tourDetails-module--title--3m35Y";
export var price = "tourDetails-module--price--VPBVg";
export var priceContainer = "tourDetails-module--priceContainer--1cOLw";
export var originalPrice = "tourDetails-module--originalPrice--CvCJo";
export var offerPrice = "tourDetails-module--offerPrice--3UZkh";
export var sliderMobile = "tourDetails-module--sliderMobile--1zYbH";