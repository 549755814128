import 'pure-react-carousel/dist/react-carousel.es.css';

import cx from 'classnames';
import { graphql } from 'gatsby';
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel';
import React from 'react';
import { connect } from 'react-redux';

import { BookingApi, ProductApi, TransportApi } from '../api';
import {
  FaqList,
  Icon,
  OverflowText,
  TextParser,
  TourGallery,
  TourHero,
  TourMain,
  TourReviews,
  TransportHero,
  FlybusSchedule,
  SreInfo,
} from '../components';
import CancellationPolicy from '../components/CancellationPolicy';
import { Desktop, Image, Link, Mobile } from '../components/index';
import * as wysiwygStyles from '../elements/PageWysiwyg/PageWysiwyg.module.scss';
import withMeta from '../hocs/withMeta';
import { ThirdParty } from '../icons';
import {
  ICurrencyOption,
  IPageContext,
  IProduct,
  IReducers,
  ITourDetails,
  ITourItem,
  ITransport,
  ITranslationsPageTourDescription,
  ITranslationsTourMainStrings,
  IFlybus,
  ICurrencyIcons,
  ICart,
} from '../interfaces';
import {
  chunkTours,
  formatPrice,
  getCurrencyConstant,
  getImageBySize,
  isTransport,
} from '../utils';
import Flybus from './flybus';
import * as styles from './styles/tourDetails.module.scss';
import {
  changeAnnouncementVisible,
  changeAnnouncementVisibleMobile,
  changeIcelandair,
} from '../../src/redux/actions/layoutActions';
import { currencies } from '../../src/constants';
import { changeCurrency } from '../../src/redux/actions/currencyActions';
import { changeLocale } from '../../src/redux/actions/translationActions';

const ICELANDIA = 'ICELANDIA';

interface IProps {
  data: {
    contentfulTour: ITourDetails;
    contentfulTranslationsPage: ITranslationsPageTourDescription;
    allContentfulTranslationsVarious: any;
  };
  pageContext: IPageContext;
  currency: ICurrencyOption;
  lang: string;
  tourDescriptionNotAvailable: string;
  tourDescriptionLoading: string;
  flybusData: IFlybus;
  changeIcelandair(parameter: boolean): void;
  changeAnnouncementVisible(parameter: boolean): void;
  changeAnnouncementVisibleMobile(parameter: boolean): void;
  changeDismiss(parameter: boolean): void;
  currencyDropdown: ICurrencyIcons;
  changeCurrency(newCurrency: ICurrencyOption, cartCount: number): void;
  cartObject: ICart | null;
  changeLocale(locale: string, lang: string): void;
  langaugeSwithcerData: ILangOptions[];
  isIcelandair: boolean;
  location: {
    pathname: string;
    search: string;
    hash: string;
    origin: string;
  };
}
interface IState {
  product: IProduct | null;
  transport: ITransport | null;
  loading: boolean;
  productYML: IDefaultPrices[] | null;
  loadingYML: boolean;
  flybus: IFlybus | null;
  slicedSlugIcelandair: string;
  slicedSlug: string;
}

interface IDefaultPrices {
  defaultPrice: number | null;
  discountPrice: number | null | undefined;
  id: number;
}
interface IOption {
  label: string;
  code: string;
  value: string;
  icon: string;
  lightIcon: string;
}
interface ILangOptions {
  iconName: string;
  text: string;
  locale: string;
  lang: string;
  langPath: string;
}
const BOX_HEIGHT = 250;

class Tour extends React.Component<IProps, IState> {
  readonly state: IState = {
    product: null,
    transport: null,
    loading: true,
    productYML: [],
    loadingYML: false,
    flybus: null,
    slicedSlugIcelandair: '',
    slicedSlug: '',
  };

  productApi = new ProductApi();
  transportApi = new TransportApi();
  bookingApi = new BookingApi();

  schedule: HTMLDivElement | null = null;
  main: HTMLDivElement | null = null;
  info: HTMLDivElement | null = null;
  gallery: HTMLDivElement | null = null;
  reviews: HTMLDivElement | null = null;
  map: HTMLDivElement | null = null;
  faq: HTMLDivElement | null = null;
  wysiwyg: HTMLDivElement | null = null;
  moreTours: HTMLDivElement | null = null;

  createExternal = async (
    bookingFlybus: IFlybus,
    hashString: string | null,
    orderId: string | null
  ) => {
    const { data } = this.props;
    if (data.contentfulTour.slug === 'icelandair') {
      this.bookingApi.createExternalBooking(
        bookingFlybus,
        hashString,
        orderId,
        this.props.lang
      );
      //console.log('createExternalBooking');
    }
  };
  insertExternal = async (
    bookingNumber: string,
    partnerBooking: string,
    status: string,
    orderId: string,
    returnHash: string
  ) => {
    const { data } = this.props;
    if (data.contentfulTour.slug === 'icelandair') {
      this.bookingApi.insertExternalBooking(
        bookingNumber,
        partnerBooking,
        status,
        orderId,
        returnHash,
        this.props.lang
      );
      //console.log('createExternalBooking');
    }
  };
  handleHash = async (
    querryHash: string | null,
    querryOrder: string | null
  ) => {
    const { data } = this.props;
    var { flybusData } = this.props;

    if (
      data.contentfulTour.slug === 'icelandair' &&
      (querryHash || querryOrder)
    ) {
      //console.log('queryHash before api call, ' + querryHash);
      const flybusResponse = await this.productApi.hashHandle(
        querryHash,
        querryOrder,
        this.props.lang
      );
      if (!!flybusResponse?.OrderId) {
        //this.state.flybus = flybus;
        flybusResponse.PartnerBookingNumber =
          flybusResponse.PartnerBookingNumber;
        this.setState({ flybus: flybusResponse });
        if (this.state.flybus) {
          flybusData = this.state.flybus;
          localStorage.setItem('flybusData', JSON.stringify(flybusData));

          this.setIsIcelandair(true);
          //console.log('flybusData:' + flybusData.Email);
          this.createExternal(flybusData, querryHash, flybusData.OrderId);
          this.insertExternal(
            'New',
            flybusData.PartnerBookingNumber,
            'New',
            flybusData.OrderId,
            ''
          );
          if (flybusData.Currency) this.setFlybusCurrency(flybusData.Currency);
          if (flybusData.LanguageId)
            this.setFlybusLanguage(flybusData.LanguageId);
        }
      } else {
        this.state.flybus = this.setInvalidHashValue();
        this.createExternal(this.state.flybus, querryHash, querryOrder);
        this.navigateToRoot();
      }
    }
  };
  setIsIcelandair = (parameter: boolean) => {
    this.props.changeIcelandair(parameter);
    this.props.changeAnnouncementVisible(!parameter);
    this.props.changeAnnouncementVisibleMobile(!parameter);
  };
  getQueryVariables = (queryUrl: string) => {
    const vars = window.location.search.substring(1).split('&');
    var querry;
    for (const v of vars) {
      const pair = v.replace(queryUrl + '=', '');
      querry = pair[0] != '' ? pair.toString() : null;
    }

    if (querry) {
      const lastUrlCharacter = querry.slice(-1);
      if (lastUrlCharacter === '/') {
        querry = querry.slice(0, -1);
      }
    }
    return querry ?? null;
  };
  setInvalidHashValue() {
    let flybus = this.state.flybus;
    return (flybus = {
      OrderId: '',
      PartnerBookingNumber: '131934',
      FirstName: '',
      LastName: '',
      Email: '',
      Currency: '',
      LanguageId: '',
      Travelers: null,
    });
  }
  navigateToRoot() {
    window.location.replace('https://re.is');
  }
  setFlybusCurrency(flybusCurrency: string) {
    const options: IOption[] = [];
    if (currencies) {
      Object.keys(currencies).map((key) => {
        const localCurrency = getCurrencyConstant(key);
        options.push(localCurrency);
      });
    }
    var option = options.filter((o) => o.label == flybusCurrency)[0];
    if (option) {
      this.props.changeCurrency(
        option as any,
        !!this.props.cartObject && !!this.props.cartObject.Items
          ? this.props.cartObject.Items.length
          : 0
      );
    }
  }
  setFlybusLanguage(flybusLanguage: string) {
    const langaugeSwithcer =
      this.props.data.allContentfulTranslationsVarious.edges.filter(
        (node: { node: { headerlanguageSwitcher: [] } }) => {
          return node;
        }
      )[0].node;
    if (langaugeSwithcer) {
      langaugeSwithcer.headerlanguageSwitcher.map((item: ILangOptions) => {
        if (item.locale == flybusLanguage) return this.changeLanguage(item);
      });
    }
  }
  changeLanguage = (item: ILangOptions) => {
    changeLocale(item.locale, item.lang);
    const slicedSlugValue =
      this.props.location.pathname.charAt(0) === '/'
        ? this.props.location.pathname.slice(1)
        : this.props.location.pathname;
    this.setState({ slicedSlug: slicedSlugValue });
    this.setState({ slicedSlugIcelandair: this.state.slicedSlug });
    if (this.props.location.search) {
      this.setState({
        slicedSlugIcelandair:
          this.state.slicedSlug + this.props.location.search,
      });
    }
    if (!this.state.slicedSlug.includes('is') && item.lang != 'EN') {
      //navigate('/' + item.langPath + this.state.slicedSlugIcelandair, {
      //replace: true,
      //});
      window.location.replace(
        this.props.location.origin +
          '/' +
          item.langPath +
          this.state.slicedSlugIcelandair
      );
    }
  };
  componentDidMount() {
    const { data, isIcelandair } = this.props;
    if (data.contentfulTour.slug === 'icelandair' && !!!isIcelandair) {
      const param = window.location.search;
      var querryOrder = null,
        queryHash = null;
      if (param.includes('orderId')) {
        querryOrder = this.getQueryVariables('orderId');
      } else if (param.includes('id')) {
        queryHash = this.getQueryVariables('id');
      }
      //console.log('queryHash from url, ' + queryHash);
      //const queryHash = this.getQueryVariables();
      if (queryHash || querryOrder) {
        this.handleHash(queryHash, querryOrder);
      } else {
        this.navigateToRoot();
      }
    }
    this.getProduct();
    if (this.props.data.contentfulTour.tours) {
      this.setProductsDataYML();
    }
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      productId: this.props.data.contentfulTour.productNumber,
    });
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.currency !== this.props.currency) {
      this.getProduct();
      if (this.props.data.contentfulTour.tours) {
        this.setProductsDataYML();
      }
    }
  }

  render() {
    const { currency, data, pageContext } = this.props;
    const { contentfulTour } = data;
    const { productId, type, productNumber, newUI } = contentfulTour;
    const { product, productYML, transport, loading, loadingYML } = this.state;
    if (data.contentfulTour.slug === 'icelandair') {
      return (
        <div>
          <TransportHero
            tour={contentfulTour}
            transport={transport}
            loadingPrice={loading}
            currency={currency.label}
          />
        </div>
      );
    } else {
      if (newUI && productNumber === 'Flybus') {
        return (
          <>
            <TransportHero
              tour={contentfulTour}
              transport={transport}
              loadingPrice={loading}
              currency={currency.label}
            />
            {
              <Flybus
                contentfulTour={contentfulTour}
                transport={transport}
                productYML={productYML}
                loadingYML={loadingYML}
                currency={currency.label}
                pageContext={pageContext}
              />
            }
          </>
        );
      }
      return (
        <div>
          {isTransport(type) ? (
            <TransportHero
              tour={contentfulTour}
              transport={transport}
              loadingPrice={loading}
              currency={this.props.currency.label}
            />
          ) : (
            <TourHero
              tour={contentfulTour}
              product={product}
              productLoaded={!loading}
              productId={productId}
            />
          )}
          {this.renderBody()}
        </div>
      );
    }
  }
  renderImage(tour: ITourItem, loading: boolean) {
    return (
      <div className={styles.imageContainer}>
        <Image
          fluidImage={tour.heroImage}
          // productPhoto={productPhoto}
          loadingProduct={loading}
          productPhotoSize='large'
          className={styles.image}
        />
        {tour.offerBanner ? (
          <div className={styles.specialOfferBanner}>
            <strong>{tour.offerBanner.title}</strong>
          </div>
        ) : null}
      </div>
    );
  }

  renderMobileImage(tour: ITourItem, loading: boolean) {
    return (
      <div className={styles.mobileImageContainer}>
        <Image
          fluidImage={tour.heroImage}
          // productPhoto={product && product.KeyPhoto}
          loadingProduct={loading}
          productPhotoSize='large'
          className={styles.imageMobile}
        />
        {tour.offerBanner ? (
          <div className={styles.specialOfferBanner}>
            <strong>{tour.offerBanner.title}</strong>
          </div>
        ) : null}
      </div>
    );
  }

  returnDefaultPrice = (productId: any) => {
    let defaultPrice = null;
    if (this.state.productYML) {
      for (const productYML of this.state.productYML) {
        if (productYML.id.toString() === productId.toString()) {
          defaultPrice = productYML.defaultPrice;
          break;
        }
      }
    }
    return defaultPrice;
  };

  returnDiscountPrice = (productId: any) => {
    let discountPrice = null;
    if (this.state.productYML) {
      for (const productYML of this.state.productYML) {
        if (productYML.id.toString() === productId.toString()) {
          if (productYML.discountPrice) {
            discountPrice = productYML.discountPrice;
          }
          break;
        }
      }
    }
    return discountPrice;
  };

  getPrice(tour: ITourItem) {
    const { productId } = tour;
    const { tourDescriptionLoading } =
      this.props.data.contentfulTranslationsPage;
    const price = this.returnDefaultPrice(productId);
    const discountPrice = this.returnDiscountPrice(productId);
    if (this.state.loadingYML) {
      return <p className={styles.price}>{tourDescriptionLoading}</p>;
    } else {
      if (!!discountPrice && !!price) {
        return (
          <div className={styles.priceContainer}>
            <p className={styles.originalPrice}>
              {formatPrice(price, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
            <p className={styles.offerPrice}>
              {formatPrice(discountPrice, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
          </div>
        );
      } else {
        return price ? (
          <div className={styles.priceContainer}>
            <p className={styles.price}>
              {formatPrice(price, this.props.currency.value)}{' '}
              {this.props.currency.label}
            </p>
          </div>
        ) : null;
      }
    }
  }

  renderTourYML(tour: any, mobile: boolean, sliderOrNot: string) {
    let desk = '';
    if (sliderOrNot === 'slider') {
      desk = 'is-3-desktop';
    } else if (sliderOrNot === 'inidividual') {
      desk = 'is-half-desktop';
    } else {
      desk = 'is-6 is-6-desktop';
    }
    const langPath = this.props.pageContext.langPath;
    const link = `${tour.pathPrefix}/${tour.slug}`;
    const loading = false;
    return (
      <div
        key={tour.id}
        className={
          mobile
            ? cx('column', 'is-half ', styles.tourItem)
            : cx('column', desk, styles.tourItem)
        }
      >
        <Link langPath={langPath} to={link} className={styles.tourItemLink}>
          {mobile
            ? this.renderMobileImage(tour, loading)
            : this.renderImage(tour, loading)}
          <div className={styles.card}>
            {tour.category && tour.category.length > 0 && (
              <p className={styles.category}>
                {tour.category[0].title.toUpperCase()}
              </p>
            )}
            <p className={styles.title}>{tour.title}</p>

            {this.getPrice(tour)}
          </div>
        </Link>
      </div>
    );
  }

  slides(slidesChunk: any, deskOrMobile: string) {
    if (deskOrMobile === 'desktop') {
      return (
        <Slider>
          {slidesChunk
            ? slidesChunk.map((value: any, index: number) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        styles.container
                      )}
                    >
                      {this.slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    } else {
      return (
        <Slider className={styles.sliderMobile}>
          {slidesChunk
            ? slidesChunk.map((value: any, index: number) => {
                return (
                  <Slide index={index} key={index}>
                    <div
                      className={cx(
                        'columns',
                        'is-multiline',
                        'is-variable',
                        'is-2',
                        'is-mobile',
                        styles.container
                      )}
                    >
                      {this.slidesHelper(value, deskOrMobile)}
                    </div>
                  </Slide>
                );
              })
            : null}
        </Slider>
      );
    }
  }

  slidesHelper(element: any, deskOrMobile: string) {
    const item: any = [];
    let deskMobileBool = true;
    if (deskOrMobile === 'desktop') {
      deskMobileBool = false;
    } else {
      deskMobileBool = true;
    }
    element.forEach((i: any) => {
      {
        item.push(this.renderTourYML(i, deskMobileBool, 'slider'));
      }
    });
    return item;
  }

  // TODO previous and next text span
  renderMoreToursYML() {
    const { contentfulTour } = this.props.data;
    const { tours } = contentfulTour;
    const toursChunk = chunkTours(tours, 4);
    const toursChunkMobile = chunkTours(tours, 2);
    return (
      <React.Fragment>
        <Mobile>
          {toursChunkMobile.length <= 1 ? (
            <div
              className={cx(
                'columns',
                'is-multiline',
                'is-variable',
                'is-2',
                'is-mobile',
                styles.container
              )}
            >
              {tours.map((tour: any) =>
                this.renderTourYML(tour, true, 'not-slider')
              )}
            </div>
          ) : null}
          {toursChunkMobile.length ? (
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={50}
              totalSlides={toursChunkMobile.length}
              dragEnabled={false}
              infinite={true}
            >
              <div className={styles.containerCarousel}>
                {this.slides(toursChunkMobile, 'mobile')}
                {toursChunkMobile.length > 1 ? (
                  <ButtonBack className={styles.buttonBack}>
                    <>
                      <Icon name='ARROWLEFT' />
                      <span>Previous</span>
                    </>
                  </ButtonBack>
                ) : null}
                {toursChunkMobile.length > 1 ? (
                  <ButtonNext className={styles.buttonNext}>
                    <>
                      <Icon name='ARROWRIGHT' />
                      <span>Next</span>
                    </>
                  </ButtonNext>
                ) : null}
              </div>
            </CarouselProvider>
          ) : null}
        </Mobile>
        <Desktop>
          <div className={cx('columns', 'is-multiline', 'centered-content')}>
            {toursChunk.length === 1
              ? tours.map((tour: any) =>
                  this.renderTourYML(tour, false, 'not-slider')
                )
              : null}
          </div>
          {toursChunk.length > 1 ? (
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={50}
              totalSlides={toursChunk.length}
              dragEnabled={false}
              infinite={true}
            >
              <div className={cx(styles.containerCarousel, 'centered-content')}>
                {this.slides(toursChunk, 'desktop')}
                <ButtonBack className={styles.buttonBack}>
                  <>
                    <Icon name='ARROWLEFT' />
                    <span>Previous</span>
                  </>
                </ButtonBack>
                <ButtonNext className={styles.buttonNext}>
                  <>
                    <Icon name='ARROWRIGHT' />
                    <span>Next</span>
                  </>
                </ButtonNext>
              </div>
            </CarouselProvider>
          ) : null}
        </Desktop>
      </React.Fragment>
    );
  }

  renderBody() {
    const { contentfulTour, contentfulTranslationsPage } = this.props.data;
    const { reviews, faq, flybusSchedule, wysiwyg } = contentfulTour;
    const { moreToursYouMightLikeHeader, faq: faqText } =
      contentfulTranslationsPage;
    const { product, transport, loading } = this.state;
    const highlights = this.renderHighlights();
    const included = this.renderIncluded();
    const thirdParty = this.renderThirdParty();

    return (
      <div
        style={{ marginBottom: 80, marginTop: this.state.transport ? 50 : 0 }}
      >
        {flybusSchedule && (
          <div className={'centered-content'} style={{ marginBottom: 100 }}>
            <FlybusSchedule {...flybusSchedule} />
          </div>
        )}
        <div ref={(elem) => (this.main = elem)}>
          <TourMain
            contentfulTour={contentfulTour}
            product={product}
            transport={transport}
            productLoaded={!loading}
            strings={
              {
                notAvailable: this.props.tourDescriptionNotAvailable,
                loading: this.props.tourDescriptionLoading,
              } as ITranslationsTourMainStrings
            }
          />
        </div>

        {(included || highlights) && (
          <div className={'centered-content'}>
            <div className={styles.moreInfo} ref={(elem) => (this.info = elem)}>
              <div className='columns'>
                {highlights}
                {included}
              </div>
              <div className='columns'>{thirdParty}</div>
              <SreInfo
                product={product}
                sreInfo={contentfulTranslationsPage.sreInfo}
              />
              <div className='columns'>
                {product && (
                  <div className='column'>
                    <CancellationPolicy
                      penaltyRules={product.CancellationPolicy.penaltyRules}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {this.renderImageGallery()}

        {reviews && (
          <div
            ref={(elem) => (this.reviews = elem)}
            className='vertical-margin'
          >
            <TourReviews reviews={reviews} />
          </div>
        )}

        {faq && (
          <div
            className={'centered-content vertical-margin'}
            ref={(elem) => (this.faq = elem)}
          >
            <h2 className='element-title'>{faqText}</h2>
            <FaqList faq={faq} />
          </div>
        )}

        {wysiwyg && (
          <div
            className='centered-content vertical-margin'
            ref={(elem) => (this.wysiwyg = elem)}
          >
            <h2 className='element-title'>{wysiwyg.title}</h2>
            <TextParser
              source={wysiwyg.text.text}
              className={cx(wysiwygStyles.text, {
                [wysiwygStyles.twoColumns]: wysiwyg.numberOfColumns === 2,
              })}
            />
          </div>
        )}

        {this.props.data.contentfulTour.tours ? (
          <div
            className='centered-content'
            ref={(elem) => (this.moreTours = elem)}
          >
            <div>
              <h2>{moreToursYouMightLikeHeader}</h2>{' '}
            </div>

            {this.renderMoreToursYML()}
          </div>
        ) : null}
        {!!transport && transport.CancellationPolicy.penaltyRules.length > 0 ? (
          <div className='centered-content'>
            <div className='columns'>
              <div className='column'>
                <CancellationPolicy
                  penaltyRules={transport.CancellationPolicy.penaltyRules}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  renderHighlights() {
    const { tourHighlights, tourHighlightsTitle } =
      this.props.data.contentfulTour;
    const { tourDescriptionTourHighlights } =
      this.props.data.contentfulTranslationsPage;
    if (!tourHighlights) {
      return null;
    }

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox}>
          <div className={styles.infoLine} />
          <h2 className={styles.navText}>
            {tourHighlightsTitle || tourDescriptionTourHighlights}
          </h2>
          <OverflowText
            text={tourHighlights.tourHighlights}
            textClassName={styles.highlights}
            height={BOX_HEIGHT}
          />
        </div>
      </div>
    );
  }

  calculateCutOffHour(hour: number) {
    const {
      tourDescriptionCancellationPolicyDays: days,
      tourDescriptionCancellationPolicyHours: hours,
    } = this.props.data.contentfulTranslationsPage;
    let result = null;
    if (hour <= 48) {
      result = hour + ' hours';
    } else {
      const Days = Math.floor(hour / 24);
      const Remainder = hour % 24;
      const Hours = Math.floor(Remainder);
      result = Days + ' ' + days + ' ';
      if (Hours > 0) {
        result += Hours + ' ' + hours + ' ';
      }
    }
    return result;
  }

  renderThirdParty() {
    const { product } = this.state;
    const { thirdPartyInfo } = this.props.data.contentfulTranslationsPage;

    if (!product || product.Vendor.toUpperCase().includes(ICELANDIA)) {
      return null;
    }

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox} style={{ padding: '15px 25px' }}>
          <div className={styles.infoLine} style={{ marginBottom: '5px' }} />
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <img
              src={ThirdParty}
              alt='ThirdParty'
              style={{ padding: '5px', width: '3em' }}
            />
            <div className={styles.thirdParty} style={{ alignSelf: 'center' }}>
              {thirdPartyInfo}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderIncluded() {
    const { included, needToKnow } = this.props.data.contentfulTour;
    const {
      tourDescriptionLoading,
      tourDescriptionNeedToKnow,
      tourDescriptionWhatsIncluded,
    } = this.props.data.contentfulTranslationsPage;

    const { loading, product } = this.state;
    let includedText;
    let needToKnowText;

    if (included) {
      includedText = included.included;
    } else if (
      product &&
      product.Inclusions &&
      product.Inclusions.Description
    ) {
      includedText = product.Inclusions.Description;
    }

    if (needToKnow) {
      needToKnowText = needToKnow.needToKnow;
    } else if (
      product &&
      product.NeedToKnow &&
      product.NeedToKnow.Description
    ) {
      needToKnowText = product.NeedToKnow.Description;
    }

    if (!includedText && !needToKnowText) {
      if (loading) {
        includedText = tourDescriptionLoading;
      } else {
        return null;
      }
    }

    let text = includedText || '';
    text += includedText && needToKnowText ? '\n' : '';
    text += needToKnowText || '';

    let title =
      tourDescriptionWhatsIncluded + ' & ' + tourDescriptionNeedToKnow;
    if (includedText && !needToKnowText) {
      title = tourDescriptionWhatsIncluded;
    } else if (needToKnowText && !includedText) {
      title = tourDescriptionNeedToKnow;
    }

    return (
      <div data-aos='fade-up' className='column' style={{ paddingTop: 0 }}>
        <div className={styles.infoBox}>
          <div className={styles.infoLine} />
          <h2 className={styles.navText}>{title}</h2>
          <OverflowText
            text={text}
            textClassName={styles.includes}
            height={BOX_HEIGHT}
          />
        </div>
      </div>
    );
  }

  renderImageGallery() {
    const { images } = this.props.data.contentfulTour;
    const { product, transport } = this.state;

    if (images && images.length > 0) {
      return (
        <div ref={(elem) => (this.gallery = elem)}>
          <TourGallery images={images} />
        </div>
      );
    }

    if (product && product.Photos) {
      return (
        <div ref={(elem) => (this.gallery = elem)}>
          <TourGallery
            images={product.Photos.map((photo, index) => ({
              id: `${index}`,
              title: photo.Description || '',
              src: getImageBySize(photo, 'large'),
            }))}
          />
        </div>
      );
    }

    if (transport && transport.Photos) {
      // Viljum ekki birta flybus mynd frá Bókun - 03.12.19
      if (transport.Id !== 9) {
        return (
          <div ref={(elem) => (this.gallery = elem)}>
            <TourGallery
              images={transport.Photos.map((photo, index) => ({
                id: `${index}`,
                title: photo.Description || '',
                src: getImageBySize(photo, 'large'),
              }))}
            />
          </div>
        );
      }
    }
    return null;
  }

  getProduct = async () => {
    this.setState({ loading: true });
    const { data, currency, lang } = this.props;
    const { type, productId } = data.contentfulTour;

    if (data.contentfulTour.isSpecialTour) {
      this.setState({ loading: false });
    } else if (isTransport(type)) {
      const id = parseInt(productId, 10);
      const transport = await this.transportApi.getTransport(
        id,
        currency.value,
        lang
      );
      this.setState({ transport });
    } else {
      const product = await this.productApi.getProduct(
        productId,
        currency.value,
        lang
      );
      this.setState({ product });
    }
    this.setState({ loading: false });
  };

  setProductsDataYML = async () => {
    this.setState({ loadingYML: true });
    const prices: IDefaultPrices[] = [];
    let priceObject: IDefaultPrices;
    const { contentfulTour } = this.props.data;
    const { currency, lang } = this.props;
    const { tours, airportTransfers } = contentfulTour;
    const tourIds: string[] = [];
    tours.forEach((element: ITourItem) => {
      tourIds.push(element.productId);
    });
    airportTransfers &&
      airportTransfers.forEach((element: ITourItem) => {
        tourIds.push(element.productId);
      });
    const defaultTourPrices = await this.productApi.getDefaultPrices(
      tourIds,
      currency.value,
      lang
    );
    if (!!defaultTourPrices && defaultTourPrices.length > 0) {
      defaultTourPrices.forEach((item) => {
        if (item.DefaultPrice) {
          priceObject = {
            defaultPrice: item.DefaultPrice,
            discountPrice: null,
            id: item.Id,
          };
        }
        if (item.DiscountPrice) {
          priceObject = {
            defaultPrice: item.DefaultPrice,
            discountPrice: item.DiscountPrice,
            id: item.Id,
          };
        }
        prices.push(priceObject);
      });
    }
    const defaultTransportPrices = await this.transportApi.getDefaultPrices(
      tourIds,
      currency.value,
      lang
    );
    if (defaultTransportPrices) {
      defaultTransportPrices.forEach((item) => {
        priceObject = {
          defaultPrice: item.DefaultPrice,
          discountPrice: item.DiscountPrice,
          id: item.Id,
        };
        prices.push(priceObject);
      });
    }
    this.setState({ loadingYML: false });
    this.setState({ productYML: prices });
  };
}

export const query = graphql`
  query TourQuery($slug: String!, $locale: String!) {
    contentfulTour(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...TourDetails
    }
    contentfulTranslationsPage(node_locale: { eq: $locale }) {
      tourInfoTransportPriceFrom
      tourDescriptionCancellationPolicyDays
      tourDescriptionCancellationPolicyHeader
      tourDescriptionCancellationPolicyHours
      tourDescriptionCancellationPolicyText
      tourDescriptionClose
      tourDescriptionLoading
      tourDescriptionNotAvailable
      tourDescriptionNeedToKnow
      tourDescriptionTourHighlights
      tourDescriptionWhatsIncluded
      faq
      moreToursYouMightLikeHeader
      thirdPartyInfo
      sreInfo
    }
    allContentfulTranslationsVarious {
      edges {
        node {
          headerlanguageSwitcher {
            iconName
            text
            locale
            lang
            langPath
          }
        }
      }
    }
  }
`;

const mapStateToProps = (reducers: IReducers) => {
  const { currency } = reducers.currencyReducer;
  const { lang } = reducers.translationReducers;
  const { isIcelandair, announcementVisible, announcementVisibleMobile } =
    reducers.layoutReducer;
  return {
    currency,
    lang,
    isIcelandair,
    announcementVisible,
    announcementVisibleMobile,
  };
};

export default connect(mapStateToProps, {
  changeIcelandair,
  changeAnnouncementVisibleMobile,
  changeAnnouncementVisible,
  changeCurrency,
  changeLocale,
})(withMeta(Tour));
