import React from 'react';

import { IHighlights } from '../../interfaces';
import Icon from '../Icon';
import Title from '../Title';
import * as styles from './index.module.scss';

const Highlights = (highlights: IHighlights) => {
  return (
    <div>
      <Title tag='h2' theme={['sizeMobileLarge', 'sizeDesktopLarge']}>
        {highlights.header}
      </Title>
      <p className={styles.subtitle}>{highlights.subtitle}</p>
      <ul className={styles.list}>
        {highlights.highlights.map((highlight) => (
          <li key={highlight.iconName}>
            <div className={styles.iconSize}>
              <Icon color='rgb(229, 129, 112)' name={highlight.iconName} />
            </div>
            <span>{highlight.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Highlights;
